export const request = async (url, options = {}, returnError = false) => {
	const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
	const response = await fetch(url, {
		...options,
		headers: {
			'X-CSRF-TOKEN': csrfToken,
			...options?.headers,
		},
	});

	const data = await response.json();

	if (response.status !== 200) {
		if (!returnError) {
			throw new Error(data?.message);
		}

		return {
			status: response.status,
			message: data?.message,
		};
	}

	return data;
};

export const searchApiCall = (inputValue) => request(`${window.location.origin}/api/v1/search/${inputValue}`);

export const updateList = (urlString, additional = null) => {
	const mainUrl = new URL(window.location);
	let additionalUrl = '';
	if (additional) {
		const prefix = mainUrl.search !== '' ? '&' : '?';
		additionalUrl = prefix + additional;
	}

	const url = `${mainUrl.origin}/${urlString}${mainUrl.search}${additionalUrl}`;
	return request(url);
};

// UPDATE SEARCH QUERY
export const updateSearchParams = (value, action, key, urlString, additional = null) => {
	const url = new URL(window.location);
	const myParam = url.searchParams.get(key);
	let tmp = new Set((key !== 'minmax' ? myParam?.split(',') : myParam) || []);

	if (!tmp.has(value) && action === 'add') {
		tmp.add(value);
	}

	if (action === 'remove') {
		if (key === 'minmax') {
			tmp = [];
		}

		if (key !== 'minmax' && tmp.has(value)) {
			tmp.delete(value);
		}
	}

	const filteredTmp = [...tmp].join(',');

	if ((filteredTmp !== '' && action === 'add') || action === 'remove') {
		url.searchParams.set(key, filteredTmp);
		if (url.searchParams.has('stran')) {
			url.searchParams.delete('stran');
		}
	}

	if (action === 'replace') {
		url.searchParams.set(key, value);
		if (key !== 'stran' && url.searchParams.has('stran')) {
			url.searchParams.delete('stran');
		}
	}

	if (filteredTmp === '' && action === 'remove') {
		url.searchParams.delete(key);
		if (url.searchParams.size === 1 && url.searchParams.has('stran')) {
			url.searchParams.delete('stran');
		}
	}

	window.history.pushState({}, '', url);
	return updateList(urlString, additional);
};

// REMOVE SEARCH QUERY
export const removeSearchParams = () => {
	const url = new URL(window.location);
	Array.from(url.searchParams.keys()).forEach((key) => {
		url.searchParams.delete(key);
	});
	window.history.pushState({}, '', url);
};

// CHECK SEARCH QUERY
export const checkSearchParams = (key) => {
	const url = new URL(window.location);
	return url.searchParams.get(key);
};
