// **********************************************************
// Quantity Counter
// **********************************************************
import { attachEvent } from '../../../../js/default';

const addRemoveQuantityCounterButton = (element, toggle) => {
	const parentElem = element.closest('.quantity-counter');
	const inputElem = parentElem.querySelector('.quantity-counter__input');
	const minusButton = parentElem.querySelector('.quantity-counter__button--remove');
	let currentVal = parseInt(inputElem.value, 10);

	if (currentVal >= 1) {
		inputElem.value = toggle ? ++currentVal : --currentVal;
		minusButton.classList.remove('is-disabled');
	}
	element.classList.toggle('is-disabled', (currentVal+1) > Number(inputElem.dataset.stock));
};

attachEvent('.quantity-counter__button--add', 'click', (ev) => {
	addRemoveQuantityCounterButton(ev.target, true);
});

attachEvent('.quantity-counter__button--remove', 'click', (ev) => {
	addRemoveQuantityCounterButton(ev.target, false);
});

// ********
// Counter Disabled Class
// ********

const quantityCounterEle = document.querySelectorAll('.quantity-counter');

if (quantityCounterEle.length > 0) {
	quantityCounterEle.forEach((el) => {
		const inputElem = el.querySelector('.quantity-counter__input');
		const minusButton = el.querySelector('.quantity-counter__button--remove');
		const currentVal = parseInt(inputElem.value, 10);

		if (currentVal > 1) {
			minusButton.classList.remove('is-disabled');
		}
	});
}
