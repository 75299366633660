// *******************************************************************************
// Open/Close Configuration Modal
// *******************************************************************************

import { attachEvent, toggleConfigurationPopup } from '../../../../js/default';

const closeConfigurationPopups = () => {
	document.querySelectorAll('.configuration-popup').forEach((el) => toggleConfigurationPopup(el, false));
};

document.addEventListener('keydown', (event) => {
	if (event.key === 'Escape') {
		closeConfigurationPopups();
	}
});

attachEvent('.configuration-popup__overlay, .configuration-popup__close, .configuration-popup__footer .btn--tertiary', 'click', (ev) => {
	toggleConfigurationPopup(ev.target.closest('.configuration-popup'), false);
	ev.preventDefault();
});

// **************************************************
// Open modal
// **************************************************

attachEvent('.is-configurable .quantity-counter__button--add', 'click', (ev) => {
	const popUpEle = ev.target.closest('.is-configurable')?.querySelector('.configuration-popup');
	if (popUpEle) {
		toggleConfigurationPopup(popUpEle, true);
	}
	ev.preventDefault();
});
