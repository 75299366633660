import { attachEvent, slideToggle } from '../../../../js/default';

const blogFilter = document.querySelector('.blog-filter');

if (blogFilter) {
	const toggleBlogFilterClass = (toggle) => {
		document.body.classList.toggle('is-open-blog-filter', toggle);
	};

	attachEvent('.blog-filter__button', 'click', (ev) => {
		const isOpen = document.body.classList.contains('is-open-blog-filter');
		toggleBlogFilterClass(!isOpen);
		ev.preventDefault();
	});

	attachEvent('.blog-filter__close, .blog-filter__overlay', 'click', (ev) => {
		toggleBlogFilterClass(false);
		ev.preventDefault();
	});

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			toggleBlogFilterClass(false);
		}
	});

	attachEvent('.blog-filter__title', 'click', (ev) => {
		const parent = ev.target.closest('.blog-filter');
		const content = parent.querySelector('.blog-filter__body');
		parent.classList.toggle('is-open');
		slideToggle(content, 300);
		ev.preventDefault();
	});
}
