// *******************************************************************************
// Open dropdown
// *******************************************************************************

import { attachEvent } from '../../../../js/default';

// Open dropdown
attachEvent('.dropdown__placeholder', 'click', (placeholder) => {
	const parent = placeholder.target.closest('.dropdown');
	parent.classList.toggle('is-open');

	if (parent.classList.contains('dropdown--search')) {
		parent.querySelector('.dropdown__search-input').focus();
	}
});

// Close on click outside
document.addEventListener('click', (ev) => {
	const dropdowns = document.querySelectorAll('.dropdown');

	dropdowns.forEach((dropdown) => {
		const clickInside = dropdown.contains(ev.target);
		const dropdownSearchInput = dropdown.querySelector('.dropdown__search-input');
		if (!clickInside) {
			dropdown.classList.remove('is-open');
			dropdownSearchInput?.blur();
		}
	});
});

export const changeValue = (option, placeholder) => {
	placeholder.innerHTML = option.innerHTML;
	placeholder.dataset.ehrm = option.dataset.ehrm;

	if (option.dataset.payer) {
		placeholder.dataset.payer = option.dataset.payer;
	}

	if (option.dataset.installments) {
		placeholder.dataset.installments = option.dataset.installments;
	}

	if (option.dataset.county) {
		placeholder.dataset.country = option.dataset.country;
	}
};

// Pick option
const getHash = () => window.location.hash.replace(/^#/, '');
attachEvent('.dropdown__list-option', 'click', (dropdownOption) => {
	const dropdownParent = dropdownOption.target.closest('.dropdown');
	const dropdownPlaceholderText = dropdownParent.querySelector('.dropdown__placeholder-text');

	if (!dropdownParent.classList.contains('dropdown--multiselect')) {
		// Change text
		changeValue(dropdownOption.target, dropdownPlaceholderText);
	}

	// Close the dropdown
	if (!dropdownParent.classList.contains('dropdown--multiselect')) {
		dropdownParent.classList.remove('is-open');
	}
	dropdownParent.classList.add('is-selected');
});

// *******************************************************************************
// Open a specific dropdown based on has in url
// *******************************************************************************

document.addEventListener('DOMContentLoaded', () => {
	const locationHash = getHash();

	if (locationHash) {
		const selectedElem = document.querySelectorAll(`#${locationHash}`);

		// Loop through elements to avoid errors if there is no element to be found
		selectedElem.forEach((el) => {
			// Set active selection
			el.setAttribute('checked', 'true');

			// Change text
			const dropdownParent = el.closest('.dropdown');
			if (dropdownParent) {
				const optionLabel = el.parentElement.querySelector('.dropdown__list-option');
				const dropdownPlaceholder = dropdownParent.querySelector('.dropdown__placeholder');
				dropdownPlaceholder.innerHTML = optionLabel.innerHTML;
			}
		});
	}

	const selectedDropdownOptions = document.querySelectorAll('.dropdown__list-item input[default]');
	selectedDropdownOptions.forEach((option) => {
		const parent = option.closest('.dropdown__list-item');
		const placeholder = option.closest('.dropdown').querySelector('.dropdown__placeholder-text');
		const selectedOption = parent.querySelector('.dropdown__list-option');
		changeValue(selectedOption, placeholder);
	});
});

// *******************************************************************************
// Dropdown Search
// *******************************************************************************

const dropdownSearch = document.querySelectorAll('.dropdown__search-input');

dropdownSearch.forEach((input) => {
	const parentElem = input.closest('.dropdown');

	const filterOptions = (input) => {
		const searchValue = input.value.toLowerCase();
		const options = parentElem.querySelectorAll('.dropdown__list-option');

		Array.from(options).forEach((option) => {
			const item = option.innerHTML.toLowerCase();
			option.closest('.dropdown__list-item').style.display = item.includes(searchValue) ? 'block' : 'none';
		});
	};

	input.addEventListener('input', () => filterOptions(input));
});

if (dropdownSearch.length > 0) {
	// Event listener to hide the list when clicking outside a dropdown
	document.addEventListener('click', () => {
		document.querySelectorAll('.dropdown__list-item').forEach((option) => {
			const input = option.closest('.dropdown').querySelector('.dropdown__search-input');
			option.style.display = 'block';
			input.value = '';
		});
	});
}
