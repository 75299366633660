// **********************************************************
// Latest Blog
// **********************************************************

import Swiper from 'swiper/bundle';
import { windowWidth, enableScrollbarSync } from '../../../../js/default';

const latestBlogSlider = document.querySelectorAll('[data-slider="latest-blog-slider"]');

const latestBlogSliderOptions = {
	slidesPerView: 1.34,
	spaceBetween: 20,
	watchOverflow: true,
	speed: 1000,
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
	breakpoints: {
		767: {
			slidesPerView: 2.5,
			spaceBetween: 20,
		},
	},
};

const initLatestBlogSwiper = () => {
	latestBlogSlider.forEach((el) => {
		const latestBlogSliderParent = el.closest('.latest-blog');
		const scrollbarEle = latestBlogSliderParent.querySelector('.swiper-scrollbar');

		latestBlogSliderOptions.scrollbar.el = scrollbarEle;

		if (windowWidth() > 767) {
			new Swiper(el, latestBlogSliderOptions);
		} else {
			enableScrollbarSync(latestBlogSliderParent);
		}
	});
};

initLatestBlogSwiper();

window.addEventListener('resize', () => {
	latestBlogSlider.forEach((el) => {
		const latestBlogSliderParent = el.closest('.latest-blog');

		if (windowWidth() > 767) {
			if (!el.swiper) {
				const swiperEle = new Swiper(el, latestBlogSliderOptions);
				swiperEle.update();
			}
		} else {
			enableScrollbarSync(latestBlogSliderParent);

			if (el.swiper) {
				el.swiper.destroy();
			}
		}
	});
});
