// **********************************************************
// Benefits
// **********************************************************

import Swiper from 'swiper/bundle';
import { windowWidth } from '../../../../js/default';

const benefitsSlider = document.querySelectorAll('[data-slider="benefits-slider"]');

const benefitsSliderOptions = {
	slidesPerView: 1.5,
	spaceBetween: 10,
	speed: 800,
	breakpoints: {
		767: {
			slidesPerView: 2.5,
			spaceBetween: 20,
		},
		1023: {
			slidesPerView: 3.4,
			spaceBetween: 20,
		},
		1199: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
	},
};

const initBenefitsSlider = () => {
	benefitsSlider.forEach((el) => {
		if (windowWidth() > 767) {
			new Swiper(el, benefitsSliderOptions);
		}
	});
};

initBenefitsSlider();

window.addEventListener('resize', () => {
	if (windowWidth() > 767) {
		benefitsSlider.forEach((el) => {
			if (!el.swiper) {
				const swiperEle = new Swiper(el, benefitsSliderOptions);
				swiperEle.update();
			}
		});
	} else {
		benefitsSlider.forEach((el) => {
			if (el.swiper) {
				el.swiper.destroy();
			}
		});
	}
});
