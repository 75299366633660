// *******************************************************************************
// Color Selection
// *******************************************************************************

import { attachEvent } from '../../../../js/default';

const configurationElement = document.querySelectorAll('.product-summary__configuration, .configuration-popup__configuration');

const getProductData = () => {
	configurationElement.forEach((element) => {
		if (!element.dataset.variations) {
			return;
		}

		const variationsData = JSON.parse(element.dataset.variations);
		const selectedColor = element.querySelector('.color-selection__color.is-selected')?.getAttribute('aria-label') || '';
		const sizeOptions = element.querySelectorAll('.dropdown__list-item input');

		sizeOptions.forEach((option) => {
			let isDisabled = true;

			for (const variation of variationsData) {
				if (option.value === variation.data[1] && selectedColor === variation.data[0] && variation.stock > 0) {
					isDisabled = false;
					break;
				}
			}

			if (isDisabled && option.checked) {
				const placeholderTextElement = element.querySelector('.dropdown__placeholder-text');
				placeholderTextElement.innerHTML = placeholderTextElement.dataset.placeholder;
				option.checked = false;
			}

			option.closest('li').classList.toggle('is-disabled', isDisabled);
		});
	});
};

attachEvent('.color-selection__color:not(.out-of-stock)', 'click', (ev) => {
	const mainParent = ev.target.closest('.color-selection');
	const parentEle = ev.target.closest('.color-selection__color');

	const selectedItems = mainParent.querySelectorAll('.color-selection__color.is-selected');
	selectedItems.forEach((item) => item.classList.remove('is-selected'));

	parentEle?.classList.toggle('is-selected');

	const inputItem = ev.target.closest('.color-selection__item');
	const input = inputItem?.querySelector('input');
	if (input) {
		input.checked = true;
	}
	const colorSelection = ev.target.closest('.color-selection');
	const anchor = ev.target.closest('a');
	if (colorSelection && anchor && anchor.ariaLabel) {
		const colorNameSelection = colorSelection.querySelector('.color-name-selection');
		if (colorNameSelection) {
			colorNameSelection.innerHTML = anchor.ariaLabel;
		}
	}
	getProductData();
	ev.preventDefault();
	const colorElement = ev.target.closest('.color-selection__color');
	const popupElement = ev.target.closest('.configuration-popup');
	if (!popupElement && colorElement && colorElement.href) {
		window.location.href = colorElement.href;
	}
});

if (configurationElement) {
	getProductData();
}
