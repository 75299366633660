import Cookies from 'js-cookie';
import {
	debounce,
	attachEvent,
	toggleMenuClass,
	toggleSearchClass,
	toggleCategoriesClass,
	toggleActiveClass,
	parseCurrencyToNumber,
	scrollToTop,
} from '../../../../js/default';

// ---
// Set header height css var to body
// ---

const headerElem = document.querySelector('.site-header');
const headerTop = document.querySelector('.site-header__top');

if (headerElem) {
	// ---
	// Set header heights
	// ---
	const getHeaderHeight = () => {
		document.documentElement.style.setProperty('--header-height', `${headerElem.clientHeight}px`);
	};

	const getHeaderTopHeight = () => {
		document.documentElement.style.setProperty('--header-top-height', `${headerTop.clientHeight}px`);
	};

	const debounceGetHeaderHeight = debounce(() => {
		getHeaderHeight();
		getHeaderTopHeight();
	}, 100);

	window.addEventListener('DOMContentLoaded', () => {
		debounceGetHeaderHeight();
	});

	window.addEventListener('resize', () => {
		debounceGetHeaderHeight();
	});

	// ---
	// Mobile menu/search
	// ---

	attachEvent('.mobile-hamburger', 'click', (ev) => {
		const isOpen = document.body.classList.contains('is-open-menu');
		const searchInput = document.querySelector('.header-search__input input');
		toggleSearchClass(false, searchInput);
		toggleMenuClass(!isOpen);
		ev.preventDefault();
	});

	// ---
	// Close mobile menu
	// ---

	attachEvent('.site-header__nav-close, .menu__overlay', 'click', (ev) => {
		toggleMenuClass(false);
		ev.preventDefault();
	});

	// ---
	// Back to main menu
	// ---

	attachEvent('[data-back-to-main-menu]', 'click', (ev) => {
		toggleCategoriesClass(false);
		ev.preventDefault();
	});

	// ---
	// Back to all categories
	// ---

	attachEvent('[data-back-to-categories]', 'click', (ev) => {
		const parentElem = ev.target.closest('.menu__products');
		toggleActiveClass(parentElem, false);
		ev.preventDefault();
	});

	const loginBtn = headerElem.querySelector('.site-header__login-btn');
	loginBtn?.addEventListener('click', (ev) => {
		if (loginBtn.dataset.user) {
			ev.preventDefault();
			headerElem.querySelector('.site-header__profile-menu').classList.toggle('is-hidden');
		}
	});
}

// ---
// Add class to body after some scroll
// ---
const addScrollingClass = () => {
	const topOffset = document.documentElement.scrollTop || document.body.scrollTop;
	document.body.classList.toggle('is-scrolled', topOffset > 100);
};

addScrollingClass();

let ticking = false;
document.addEventListener('scroll', () => {
	if (!ticking) {
		window.requestAnimationFrame(() => {
			addScrollingClass();
			ticking = false;
		});
		ticking = true;
	}
});

let lastScrollTop = 0;
window.addEventListener(
	'scroll',
	() => {
		const isScrolledTop = window.pageYOffset || document.documentElement.scrollTop;
		document.body.classList.toggle('is-scrolled-up', isScrolledTop < lastScrollTop);
		lastScrollTop = isScrolledTop <= 0 ? 0 : isScrolledTop;
	},
	false,
);

const logoutButton = document.querySelector('.site-header__logout-btn');
if (logoutButton) {
	logoutButton.addEventListener('click', () => {
		Cookies.remove('cart_hash');
	});
}

const cartBtn = document.querySelector('.cart__btn');
if (cartBtn) {
	cartBtn.addEventListener('click', (ev) => {
		ev.preventDefault();
		window.dataLayer = window.dataLayer || [];

		const valueText = document.querySelector('.cart__popup-footer-right');
		const valueNumber = parseCurrencyToNumber('sl-SI', 'EUR', valueText.textContent);
		const products = document.querySelectorAll('.product-preview');
		let itemsArray = [];
		products?.forEach((product) => {
			const rawData = document.getElementById(`product-ld-data-${product.dataset.id}`);
			const parsedData = JSON.parse(rawData.textContent);

			itemsArray = [
				...itemsArray,
				{
					item_id: parsedData.item_id,
					item_name: parsedData.item_name,
					discount: parsedData.discount,
					item_brand: parsedData.item_brand,
					item_category: parsedData.item_category,
					item_category2: parsedData.item_category,
					// item_list_id: 'related_products',
					// item_list_name: 'Related Products',
					item_variant: parsedData?.variation,
					price: parseCurrencyToNumber('sl-SI', 'EUR', parsedData.price),
					quantity: parsedData.quantity,
				},
			];
		});

		dataLayer.push({
			event: 'view_cart',
			ecommerce: {
				currency: 'EUR',
				value: valueNumber,
				items: itemsArray,
			},
		});
	});

	const searchInput = document.querySelector('.trigger-search');
	if (searchInput) {
		searchInput.addEventListener('click', (e) => {
			const headerSearch = document.querySelector('#header-search');
			e.preventDefault();
			scrollToTop(headerSearch);
			headerSearch.focus();
		});
	}
}

window.addEventListener('DOMContentLoaded', () => {
	const userProfileBtn = document.querySelector('.site-header__user-btn');
	if (userProfileBtn) {
		let isDropdownOpen;
		const mobileDropdown = document.querySelector('.dropdown--mobile');

		userProfileBtn.addEventListener('click', (e) => {
			e.stopPropagation();
			isDropdownOpen = !isDropdownOpen;
			mobileDropdown.classList.toggle('account-dropdown__dropdown__close');
			mobileDropdown.classList.toggle('account-dropdown__dropdown__open');
		});

		window.addEventListener('click', (event) => {
			if (isDropdownOpen && mobileDropdown && !mobileDropdown.contains(event.target)) {
				mobileDropdown.classList.toggle('account-dropdown__dropdown__close', true);
				mobileDropdown.classList.toggle('account-dropdown__dropdown__open', false);
				isDropdownOpen = false;
			}
		});

		const closeBtn = mobileDropdown.querySelector('.icon__close > .icon');
		if (closeBtn) {
			closeBtn.addEventListener('click', () => {
				mobileDropdown.classList.toggle('account-dropdown__dropdown__close', true);
				mobileDropdown.classList.toggle('account-dropdown__dropdown__open', false);
				isDropdownOpen = false;
			});
		}
	}
});
