// **********************************************************
// Top Products
// **********************************************************

import Swiper from 'swiper/bundle';
import { windowWidth, enableScrollbarSync } from '../../../../js/default';

const topProducts = document.querySelectorAll('[data-slider="top-products-slider"]');

const topProductsOptions = {
	slidesPerView: 1.34,
	spaceBetween: 20,
	watchOverflow: true,
	speed: 1000,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
	breakpoints: {
		767: {
			freeMode: true,
			slidesPerView: 2,
			spaceBetween: 26,
		},
		1199: {
			freeMode: false,
			slidesPerView: 3,
			spaceBetween: 26,
		},
	},
};

const initTopProductSlider = () => {
	topProducts.forEach((el) => {
		const topProductsEle = el.closest('.top-products');
		const arrowRight = topProductsEle.querySelector('.top-products__header .swiper-button-next');
		const arrowLeft = topProductsEle.querySelector('.top-products__header .swiper-button-prev');
		const scrollbarEle = topProductsEle.querySelector('.top-products__swiper-scrollbar');

		topProductsOptions.navigation.nextEl = arrowRight;
		topProductsOptions.navigation.prevEl = arrowLeft;
		topProductsOptions.scrollbar.el = scrollbarEle;

		if (windowWidth() > 767) {
			new Swiper(el, topProductsOptions);
		} else {
			enableScrollbarSync(topProductsEle, scrollbarEle);
		}
	});
};

initTopProductSlider();

window.addEventListener('resize', () => {
	topProducts.forEach((el) => {
		const topProductsEle = el.closest('.top-products');
		const scrollbarEle = topProductsEle.querySelector('.top-products__swiper-scrollbar');

		if (windowWidth() > 767) {
			if (!el.swiper) {
				const options = { ...topProductsOptions };
				options.scrollbar.el = scrollbarEle;
				const swiperEle = new Swiper(el, options);
				swiperEle.update();
			}
		} else {
			enableScrollbarSync(topProductsEle, scrollbarEle);

			if (el.swiper) {
				el.swiper.destroy();
			}
		}
	});
});
