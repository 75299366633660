// **************************************************
// Accordion toggle
// **************************************************
import { attachEvent, slideUp, slideDown, scrollToElement } from '../../../../js/default';

function closeAccordions() {
	const openAccordions = document.querySelectorAll('.accordion-list__item.is-open');
	openAccordions.forEach((openAccordion) => {
		const openContent = openAccordion.querySelector('.accordion-list__item-content');
		openAccordion.classList.remove('is-open');
		slideUp(openContent, 300);
	});
}

const accordionTitles = document.querySelectorAll('.accordion-list__item-title');
accordionTitles.forEach((accordionTitle) => {
	const accordionItem = accordionTitle.closest('.accordion-list__item');
	const accordionContent = accordionItem.querySelector('.accordion-list__item-content');

	accordionTitle.addEventListener('click', () => {
		if (accordionItem.classList.contains('is-open')) {
			closeAccordions();
		} else {
			closeAccordions();
			accordionItem.classList.add('is-open');
			slideDown(accordionContent, 300, 'block');
			setTimeout(() => {
				scrollToElement(accordionItem);
			}, 500);
		}
	});
});

// Show More Content
attachEvent('.accordion-list__item--scrollable-content .accordion-list__item-primary-button', 'click', (ev) => {
	const parent = ev.target.closest('.accordion-list__item--scrollable-content');
	parent.classList.add('is-open-content');
	ev.preventDefault();
});

const readMoreBtn = document.querySelector('.accordion-list__item-primary-button');
const innerDesc = document.querySelector('.accordion-list__item-description-inner.height-selector');

if (readMoreBtn && innerDesc) {
	if (innerDesc.offsetHeight > 300) {
		innerDesc.classList.toggle('limit-height', true);

		readMoreBtn.addEventListener('click', () => {
			innerDesc.querySelector('.gradient').classList.toggle('hidden', true);
			readMoreBtn.style.display = 'none';
			innerDesc.classList.toggle('limit-height', false);
			slideDown(innerDesc, 1000, null, 300);
		});
	} else {
		innerDesc.querySelector('.gradient').classList.toggle('hidden', true);
		readMoreBtn.style.display = 'none';
	}
}
