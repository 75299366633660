// **********************************************************
// Categories Module
// **********************************************************

import Swiper from 'swiper/bundle';
import { windowWidth, enableScrollbarSync } from '../../../../js/default';

const categoriesSlider = document.querySelectorAll('[data-slider="categories-slider"]');

const categoriesSliderOptions = {
	slidesPerView: 2.6,
	spaceBetween: 24,
	speed: 600,
	watchOverflow: true,
	freeMode: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
	breakpoints: {
		767: {
			slidesPerView: 5,
			spaceBetween: 20,
		},
		1023: {
			freeMode: false,
			slidesPerView: 6,
			spaceBetween: 20,
		},
		1199: {
			freeMode: false,
			slidesPerView: 8,
			spaceBetween: 20,
		},
	},
};

const initCategoriesSlider = () => {
	categoriesSlider.forEach((el) => {
		const categoriesSliderEle = el.closest('.categories-module');
		const arrowRight = categoriesSliderEle.querySelector('.swiper-button-next');
		const arrowLeft = categoriesSliderEle.querySelector('.swiper-button-prev');
		const scrollbarEle = categoriesSliderEle.querySelector('.swiper-scrollbar');

		categoriesSliderOptions.navigation.nextEl = arrowRight;
		categoriesSliderOptions.navigation.prevEl = arrowLeft;
		categoriesSliderOptions.scrollbar.el = scrollbarEle;

		if (windowWidth() > 767) {
			new Swiper(el, categoriesSliderOptions);
		} else {
			enableScrollbarSync(categoriesSliderEle);
		}
	});
};

initCategoriesSlider();

window.addEventListener('resize', () => {
	categoriesSlider.forEach((el) => {
		const categoriesSliderEle = el.closest('.categories-module');

		if (windowWidth() > 767) {
			if (!el.swiper) {
				const swiperEle = new Swiper(el, categoriesSliderOptions);
				swiperEle.update();
			}
		} else {
			enableScrollbarSync(categoriesSliderEle);

			if (el.swiper) {
				el.swiper.destroy();
			}
		}
	});
});
