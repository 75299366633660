import { attachEvent, toggleBodyScrollLock } from '../../../../js/default';

const checkoutCartBtn = document.querySelector('.checkout-header__cart-btn');

if (checkoutCartBtn) {
	const toggleCartSummaryClass = (toggle) => {
		document.body.classList.toggle('is-open-cart-summary', toggle);
		toggleBodyScrollLock(toggle);
	};

	attachEvent('[data-cart-summary]', 'click', (ev) => {
		const isOpen = document.body.classList.contains('is-open-cart-summary');
		toggleCartSummaryClass(!isOpen);
		ev.preventDefault();
	});
}
