import { attachEvent, scrollToTop, slideToggle } from '../../../../js/default';
import { checkSearchParams, removeSearchParams, updateList, updateSearchParams } from '../../../../js/api';

const brandFilter = document.querySelector('.brand-filter');

if (brandFilter) {
	const brandTotalText = document.querySelector('.brand-list__information:not(.d-md-none)');
	const brandFilter = document.querySelector('.brand-filter__body-inner');
	const brandFilterInputs = brandFilter.querySelectorAll('input');
	const brandFilterFooter = document.querySelector('.brand-filter__body-footer');
	const selectionRows = document.querySelectorAll('.brand-filter__selections-row');
	const brandFilterSelections = document.querySelectorAll('.brand-filter__selections');
	const brandFilterCounter = document.querySelector('.brand-filter__counter');
	const container = document.querySelector('.brand-list__container');
	const urlString = 'api/v1/znamke/filter';

	const updateContent = (data) => {
		container.innerHTML = data?.section;
		brandTotalText.innerHTML = data?.total_text;
	};

	const enableDisable = (show) => {
		if (!show) {
			scrollToTop(container);
		}
		brandFilter.classList.toggle('is-disabled', show);
		container.classList.toggle('is-disabled', show);
	};

	const toggleBrandFilterClass = (toggle) => {
		document.body.classList.toggle('is-open-brand-filter', toggle);
	};

	const checkInputsStatus = () => {
		const checkedInputs = document.querySelectorAll('.brand-filter__body-inner input:checked');

		brandFilterFooter.classList.toggle('has-items', checkedInputs.length > 0);
		brandFilterSelections.forEach((el) => {
			el.classList.toggle('hide', checkedInputs.length === 0);
		});

		brandFilterCounter.classList.toggle('hide', checkedInputs.length === 0);
		brandFilterCounter.innerHTML = checkedInputs.length;
	};

	const removePageLink = (slug) => {
		if (window.location.href.includes(slug)) {
			const newUrl = window.location.href.replace(slug, '');
			window.history.replaceState(null, null, newUrl);
		}
	};

	const addSelection = async (element) => {
		enableDisable(true);

		const parentElem = element.closest('.checkbox');
		const inputId = element.getAttribute('id');
		const inputName = element.getAttribute('name');
		const inputText = parentElem?.querySelector('.checkbox__label-text').textContent;

		const htmlString = `
                <div class="brand-filter__selections-item" data-brand="${inputId}">
                    <span class="brand-filter__selections-text">${inputText}</span>
                    <button aria-label="remove ${inputText}" class="brand-filter__selections-close">
                        <i class="icon : icon-close : blue : p-none"></i>
                    </button>
                </div>
            `;

		const targetNode = document.querySelectorAll(`[data-brand="${inputId}"]`);

		if (element.checked) {
			selectionRows.forEach((row) => {
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = htmlString.trim();
				row.appendChild(tempDiv.firstChild);
			});
		} else {
			targetNode.forEach((item) => {
				item.remove();
			});
		}

		let queryString = String(inputId);

		const locationHref = window.location.href;
		const isolatedPart = locationHref.match(/\d+-[a-zA-Z]+/);
		if (isolatedPart?.length) {
			removePageLink(isolatedPart[0]);
			queryString = `${queryString},${isolatedPart[0].split('-')[0]}`;
		}
		const data = await updateSearchParams(queryString, element.checked ? 'add' : 'remove', 'brands', urlString);
		updateContent(data);

		checkInputsStatus();
		enableDisable(false);
	};

	// Open/Close filter on mobile
	attachEvent('.brand-filter__button', 'click', (ev) => {
		toggleBrandFilterClass(!document.body.classList.contains('is-open-brand-filter'));
		ev.preventDefault();
	});

	// Close filter on mobile
	attachEvent('[data-close-brand-filter]', 'click', (ev) => {
		toggleBrandFilterClass(false);
		ev.preventDefault();
	});

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			toggleBrandFilterClass(false);
		}
	});

	// Open/Close filter accordion - Desktop
	attachEvent('.brand-filter__item-title', 'click', (ev) => {
		const parent = ev.target.closest('.brand-filter__item');
		const content = parent.querySelector('.brand-filter__item-content');
		parent.classList.toggle('is-active');
		slideToggle(content, 300, 'block');
		ev.preventDefault();
	});

	attachEvent('.brand-filter__selections-close', 'click', async (ev) => {
		const parent = ev.target.closest('.brand-filter__selections-item');
		const brandId = parent.getAttribute('data-brand');
		const brandName = parent.textContent.trim();
		const targetInput = document.getElementById(brandId);
		targetInput.checked = false;
		parent.remove();

		enableDisable(true);

		removePageLink(`${brandId}-${brandName}`);
		const data = await updateSearchParams(String(brandId), 'remove', 'brands', urlString);
		updateContent(data);
		enableDisable(false);

		checkInputsStatus();
		ev.preventDefault();
	});

	// Reset Filter
	attachEvent('.brand-filter__reset', 'click', async (ev) => {
		removeSearchParams();
		brandFilterInputs.forEach((input) => {
			input.checked = false;
		});

		checkInputsStatus();

		selectionRows.forEach((row) => {
			row.innerHTML = '';
		});
		ev.preventDefault();

		enableDisable(true);
		const data = await updateList(urlString);
		updateContent(data);
		enableDisable(false);
	});

	// Filter
	brandFilterInputs.forEach((input) => {
		input.addEventListener('change', async (el) => {
			await addSelection(el.target);
		});
	});

	container.addEventListener('click', async (event) => {
		const locationHref = window.location.href;
		const isolatedPart = locationHref.match(/\d+-[a-zA-Z]+/);
		if (event.target.closest('a.pagination__link')) {
			event.preventDefault();
			const pageNumber = event.target.closest('a.pagination__link').getAttribute('data-url');

			enableDisable(true);
			const data = await updateSearchParams(
				pageNumber,
				'replace',
				'stran',
				isolatedPart?.length ? `${urlString}/${isolatedPart[0]}` : urlString,
			);
			updateContent(data);
			enableDisable(false);
		}

		if (event.target.closest('button.show-more-button')) {
			const pageNumber = event.target.closest('button.show-more-button').getAttribute('data-url');

			enableDisable(true);
			const data = await updateSearchParams(
				pageNumber,
				'replace',
				'stran',
				isolatedPart?.length ? `${urlString}/${isolatedPart[0]}` : urlString,
			);

			updateContent(data);
			enableDisable(false);
		}
	});
}
