import noUiSlider from 'nouislider';
import wNumb from 'wnumb';

let rangeSliderElem = document.querySelectorAll('#range-slider-input');
const initializeSlider = () => {
	rangeSliderElem = document.querySelectorAll('#range-slider-input');

	if (rangeSliderElem) {
		rangeSliderElem.forEach((el) => {
			const rangeFrom = el.getAttribute('data-from');
			const rangeTo = el.getAttribute('data-to');
			const rangeMin = el.getAttribute('data-min');
			const rangeMinNum = parseInt(rangeMin, 10);
			const rangeMax = el.getAttribute('data-max');
			const rangeMaxNum = parseInt(rangeMax, 10);

			const parentEle = el.closest('.range-slider');
			const rangeStartInput = parentEle.querySelector('.range-slider__start-range input');
			const rangeEndInput = parentEle.querySelector('.range-slider__end-range input');

			const rangeValues = [rangeStartInput, rangeEndInput];

			noUiSlider.create(el, {
				start: [rangeFrom, rangeTo],
				connect: true,
				range: {
					min: rangeMinNum,
					max: rangeMaxNum,
				},
				tooltips: false,
				format: wNumb({
					mark: ',',
					thousand: '.',
					decimals: 2,
					// suffix: ' €',
				}),
			});

			el.noUiSlider.on('update', (values, handle) => {
				rangeValues[handle].value = values[handle];
			});

			rangeStartInput.addEventListener('blur', (input) => {
				el.noUiSlider.set([input.target.value, null]);
			});

			rangeEndInput.addEventListener('blur', (input) => {
				el.noUiSlider.set([null, input.target.value]);
			});
		});
	}
};

export default initializeSlider;
