/**
 * Slovenian language package
 * Translated by Specto
 */
const sl_SI = {
	base64: { default: 'Prosimo, vnesite veljavno kodo base 64.' },
	between: { default: 'Vnesite vrednost med %s in %s', notInclusive: 'Vnesite vrednost strogo med %s in %s' },
	bic: { default: 'Vnesite veljavno številko BIC' },
	callback: { default: 'Vnesite veljavno vrednost' },
	choice: {
		between: 'Izberite %s - %s možnosti',
		default: 'Vnesite veljavno vrednost',
		less: 'Izberite najmanj %s možnosti',
		more: 'Izberite največ %s možnosti',
	},
	color: { default: 'Vnesite veljavno barvo' },
	creditCard: { default: 'Vnesite veljavno številko kreditne kartice' },
	cusip: { default: 'Vnesite veljavno številko CUSIP' },
	date: {
		default: 'Vnesite veljavni datum',
		max: 'Prosimo, vnesite datum pred %s',
		min: 'Prosimo, vnesite datum po %s',
		range: 'Vnesite datum v razponu %s - %s',
	},
	different: { default: 'Vnesite drugo vrednost' },
	digits: { default: 'Vnesite samo številke' },
	ean: { default: 'Vnesite veljavno številko EAN' },
	ein: { default: 'Vnesite veljavno številko EINs' },
	emailAddress: { default: 'Vnesite veljaven e-poštni naslov' },
	file: { default: 'Izberite veljavno datoteko' },
	greaterThan: { default: 'Vnesite vrednost, ki je večja ali enaka %s', notInclusive: 'Vnesite vrednost, večjo od %s' },
	grid: { default: 'Prosimo, vnesite veljavno številko GRId' },
	hex: { default: 'Vnesite veljavno šestnajstiško število' },
	iban: {
		countries: {
			AD: 'Andora',
			AE: 'Združeni arabski emirati',
			AL: 'Albanija',
			AO: 'Angola',
			AT: 'Avstrija',
			AZ: 'Azerbajdžan',
			BA: 'Bosna in Hercegovina',
			BE: 'Belgija',
			BF: 'Burkina Faso',
			BG: 'Bolgarija',
			BH: 'Bahrajn',
			BI: 'Burundi',
			BJ: 'Benin',
			BR: 'Brazilija',
			CH: 'Švica',
			CI: 'Slonokoščena obala',
			CM: 'Kamerun',
			CR: 'Kostarika',
			CV: 'Zelenortski otoki',
			CY: 'Ciper',
			CZ: 'Češka republika',
			DE: 'Nemčija',
			DK: 'Danska',
			DO: 'Dominikanska republika',
			DZ: 'Alžirija',
			EE: 'Estonija',
			ES: 'Španija',
			FI: 'Finska',
			FO: 'Ferski otoki',
			FR: 'Francija',
			GB: 'Združeno kraljestvo',
			GE: 'Georgia',
			GI: 'Gibraltar',
			GL: 'Grenlandija',
			GR: 'Grčija',
			GT: 'Gvatemala',
			HR: 'Hrvaška',
			HU: 'Madžarska',
			IE: 'Irska',
			IL: 'Izrael',
			IR: 'Iran',
			IS: 'Islandija',
			IT: 'Italija',
			JO: 'Jordanija',
			KW: 'Kuvajt',
			KZ: 'Kazahstan',
			LB: 'Libanon',
			LI: 'Lihtenštajn',
			LT: 'Litva',
			LU: 'Luksemburg',
			LV: 'Latvija',
			MC: 'Monako',
			MD: 'Moldavija',
			ME: 'Črna gora',
			MG: 'Madagaskar',
			MK: 'Makedonija',
			ML: 'Mali',
			MR: 'Mavretanija',
			MT: 'Malta',
			MU: 'Mauritius',
			MZ: 'Mozambik',
			NL: 'Nizozemska',
			NO: 'Norveška',
			PK: 'Pakistan',
			PL: 'Poljska',
			PS: 'Palestina',
			PT: 'Portugalska',
			QA: 'Katar',
			RO: 'Romunija',
			RS: 'Srbija',
			SA: 'Saudova Arabija',
			SE: 'Švedska',
			SI: 'Slovenija',
			SK: 'Slovaška',
			SM: 'San Marino',
			SN: 'Senegal',
			TL: 'Vzhodni Timor',
			TN: 'Tunizija',
			TR: 'Turčija',
			VG: 'Deviški otoki, britanski',
			XK: 'Republika Kosovo',
		},
		country: 'Vnesite veljavno številko IBAN v %s',
		default: 'Vnesite veljavno številko IBAN',
	},
	id: {
		countries: {
			BA: 'Bosna in Hercegovina',
			BG: 'Bolgarija',
			BR: 'Brazilija',
			CH: 'Švica',
			CL: 'Čile',
			CN: 'Kitajska',
			CZ: 'Češka',
			DK: 'Danska',
			EE: 'Estonija',
			ES: 'Španija',
			FI: 'Finska',
			HR: 'Hrvaška',
			IE: 'Irska',
			IS: 'Islandija',
			LT: 'Litva',
			LV: 'Latvija',
			ME: 'Črna gora',
			MK: 'Makedonija',
			NL: 'Nizozemska',
			PL: 'Poljska',
			RO: 'Romunija',
			RS: 'Srbija',
			SE: 'Švedska',
			SI: 'Slovenija',
			SK: 'Slovaška',
			SM: 'San Marino',
			TH: 'Tajska',
			TR: 'Turčija',
			ZA: 'Južna Afrika',
		},
		country: 'Vnesite veljavno identifikacijsko številko v %s',
		default: 'Vnesite veljavno identifikacijsko številko',
	},
	identical: { default: 'Vnesite enako vrednost' },
	imei: { default: 'Vnesite veljavno številko IMEI' },
	imo: { default: 'Vnesite veljavno številko IMO' },
	integer: { default: 'Vnesite veljavno številko' },
	ip: { default: 'Vnesite veljavni naslov IP', ipv4: 'Vnesite veljavni naslov IPv4', ipv6: 'Vnesite veljavni naslov IPv6' },
	isbn: { default: 'Vnesite veljavno številko ISBN' },
	isin: { default: 'Vnesite veljavno številko ISIN' },
	ismn: { default: 'Vnesite veljavno številko ISMN' },
	issn: { default: 'Vnesite veljavno številko ISSN' },
	lessThan: { default: 'Vnesite vrednost, ki je manjša ali enaka %s', notInclusive: 'Prosimo, vnesite vrednost, manjšo od %s' },
	mac: { default: 'Vnesite veljavni naslov MAC' },
	meid: { default: 'Vnesite veljavno številko MEID' },
	notEmpty: { default: 'Vnesite vrednost' },
	numeric: { default: 'Prosimo, vnesite veljavno številko plovca' },
	phone: {
		countries: {
			AE: 'Združeni arabski emirati',
			BG: 'Bolgarija',
			BR: 'Brazilija',
			CN: 'Kitajska',
			CZ: 'Češka',
			DE: 'Nemčija',
			DK: 'Danska',
			ES: 'Španija',
			FR: 'Francija',
			GB: 'Združeno kraljestvo',
			IN: 'Indija',
			MA: 'Maroko',
			NL: 'Nizozemska',
			PK: 'Pakistan',
			RO: 'Romunija',
			RU: 'Rusija',
			SK: 'Slovaška',
			TH: 'Tajska',
			US: 'ZDA',
			VE: 'Venezuela',
		},
		country: 'Vnesite veljavno telefonsko številko v %s',
		default: 'Vnesite veljavno telefonsko številko',
	},
	promise: { default: 'Vnesite veljavno vrednost' },
	regexp: { default: 'Vnesite vrednost, ki ustreza vzorcu' },
	remote: { default: 'Vnesite veljavno vrednost' },
	rtn: { default: 'Vnesite veljavno številko RTN' },
	sedol: { default: 'Vnesite veljavno številko SEDOL' },
	siren: { default: 'Vnesite veljavno številko SIREN' },
	siret: { default: 'Vnesite veljavno številko SIRET' },
	step: { default: 'Prosimo, vnesite veljavni korak %s' },
	stringCase: { default: 'Vnesite samo male črke', upper: 'Prosimo, vnesite samo velike črke' },
	stringLength: {
		between: 'Vnesite vrednost, dolgo med %s in %s znakov',
		default: 'Prosimo, vnesite vrednost z veljavno dolžino',
		less: 'Vnesite manj kot %s znakov',
		more: 'Vnesite več kot %s znakov',
	},
	uri: { default: 'Vnesite veljavni URI' },
	uuid: { default: 'Vnesite veljavno številko UUID', version: 'Vnesite veljavno številko različice UUID %s' },
	vat: {
		countries: {
			AT: 'Avstrija',
			BE: 'Belgija',
			BG: 'Bolgarija',
			BR: 'Brazilija',
			CH: 'Švica',
			CY: 'Ciper',
			CZ: 'Češka',
			DE: 'Nemčija',
			DK: 'Danska',
			EE: 'Estonija',
			EL: 'Grčija',
			ES: 'Španija',
			FI: 'Finska',
			FR: 'Francija',
			GB: 'Združeno kraljestvo',
			GR: 'Grčija',
			HR: 'Hrvaška',
			HU: 'Madžarska',
			IE: 'Irska',
			IS: 'Islandija',
			IT: 'Italija',
			LT: 'Litva',
			LU: 'Luksemburg',
			LV: 'Latvija',
			MT: 'Malta',
			NL: 'Nizozemska',
			NO: 'Norveška',
			PL: 'Poljska',
			PT: 'Portugalska',
			RO: 'Romunija',
			RS: 'Srbija',
			RU: 'Rusija',
			SE: 'Švedska',
			SI: 'Slovenija',
			SK: 'Slovaška',
			VE: 'Venezuela',
			ZA: 'Južna Afrika',
		},
		country: 'Vnesite veljavno številko DDV v %s',
		default: 'Prosimo, vnesite veljavno številko DDV',
	},
	vin: { default: 'Prosimo, vnesite veljavno številko DDV' },
	zipCode: {
		countries: {
			AT: 'Avstrija',
			BG: 'Bolgarija',
			BR: 'Brazilija',
			CA: 'Kanada',
			CH: 'Švica',
			CZ: 'Češka',
			DE: 'Nemčija',
			DK: 'Danska',
			ES: 'Španija',
			FR: 'Francija',
			GB: 'Združeno kraljestvo',
			IE: 'Irska',
			IN: 'Indija',
			IT: 'Italija',
			MA: 'Maroko',
			NL: 'Nizozemska',
			PL: 'Poljska',
			PT: 'Portugalska',
			RO: 'Romunija',
			RU: 'Rusija',
			SE: 'Švedska',
			SG: 'Singapur',
			SK: 'Slovaška',
			US: 'ZDA',
		},
		country: 'Vnesite veljavno poštno številko v %s',
		default: 'Vnesite veljavno poštno številko',
	},
};

export { sl_SI };
