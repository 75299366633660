// *******************************************************************************
// Delivery Methods
// *******************************************************************************

import { attachEvent } from '../../../../js/default';

attachEvent('.payment-methods__heading input', 'change', (ev) => {
	const mainParent = ev.target.closest('.payment-methods');
	const parentEle = ev.target.closest('.payment-methods__list-item');
	const allContentEle = mainParent.querySelectorAll('.payment-methods .payment-methods__list-item-content');
	const contentEle = parentEle.querySelector('.payment-methods__list-item-content');

	allContentEle.forEach((element) => {
		if (element !== contentEle) {
			element.classList.add('hide');
			element.querySelectorAll('input').forEach((inputElem) => {
				if (inputElem.checked) {
					inputElem.checked = false;
				}

				if (inputElem.type === 'text' || inputElem.type === 'textarea') {
					inputElem.value = '';
				}
			});
		}
	});

	contentEle?.classList.remove('hide');
});
