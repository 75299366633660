// **********************************************************
// Product Showcase
// **********************************************************

import Swiper from 'swiper/bundle';
import { Fancybox } from '@fancyapps/ui';
import Drift from 'drift-zoom';

const productShowcaseMain = document.querySelectorAll('[data-slider="product-showcase-main"]');
const productShowcaseThumb = document.querySelectorAll('[data-slider="product-showcase-thumb"]');

const productShowcaseThumbOptions = {
	slidesPerView: 3.5,
	spaceBetween: 10,
	speed: 600,
	direction: 'horizontal',
	mousewheel: true,
	breakpoints: {
		767: {
			slidesPerView: 5,
			spaceBetween: 20,
			direction: 'vertical',
		},
		1023: {
			slidesPerView: 4,
			spaceBetween: 20,
			direction: 'vertical',
		},
		1199: {
			slidesPerView: 5,
			spaceBetween: 20,
			direction: 'vertical',
		},
		1440: {
			slidesPerView: 6,
			spaceBetween: 20,
			direction: 'vertical',
		},
	},
};

productShowcaseThumb.forEach((el) => new Swiper(el, productShowcaseThumbOptions));

const productShowcaseMainOptions = {
	slidesPerView: 1,
	spaceBetween: 0,
	speed: 600,
	watchOverflow: true,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	thumbs: {
		swiper: false,
	},
};

productShowcaseMain.forEach((el, i) => {
	const productShowcaseEle = el.closest('.product-showcase');
	const arrowRight = productShowcaseEle.querySelector('.swiper-button-next');
	const arrowLeft = productShowcaseEle.querySelector('.swiper-button-prev');

	productShowcaseMainOptions.navigation.nextEl = arrowRight;
	productShowcaseMainOptions.navigation.prevEl = arrowLeft;

	productShowcaseMainOptions.thumbs.swiper = productShowcaseThumb[i].swiper;
	new Swiper(el, productShowcaseMainOptions);
});

// **********************************************************
// FancyBox
// **********************************************************

Fancybox.bind('[data-fancybox]', {
	idle: 10000,
});

// **********************************************************
// Image Zoom
// **********************************************************

const productImages = document.querySelectorAll('.product-showcase__main-slider-slide img');

productImages.forEach((el) => {
	const parentEle = el.closest('.product-showcase__main-slider');

	const options = {
		paneContainer: parentEle,
		inlinePane: false,
		handleTouch: false,
		zoomFactor: 2,
	};

	new Drift(el, options);
});
