// *******************************************************************************
// Toggle Hidden Inputs
// *******************************************************************************

import { attachEvent } from '../../../../js/default';

attachEvent('.toggle-hidden-inputs .checkbox input', 'change', (ev) => {
	const parentEle = ev.target.closest('.toggle-hidden-inputs');
	const hiddenEle = parentEle.querySelector('.toggle-hidden-inputs__list');

	hiddenEle?.classList.toggle('hide');
});
