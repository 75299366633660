// *******************************************************************************
// Open/Close Gift popup Modal
// *******************************************************************************

import { attachEvent, toggleConfigurationPopup } from '../../../../js/default';

const closeConfigurationPopups = () => {
	document.querySelectorAll('.gift-popup-box').forEach((el) => toggleConfigurationPopup(el, false));
};

document.addEventListener('keydown', (event) => {
	if (event.key === 'Escape') {
		closeConfigurationPopups();
	}
});

attachEvent('.gift-popup-box__overlay, .gift-popup-box__close', 'click', (ev) => {
	toggleConfigurationPopup(ev.target.closest('.gift-popup-box'), false);
	ev.preventDefault();
});

