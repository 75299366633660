import { attachEvent } from '../../../../js/default';

const tooltip = document.querySelector('.tooltip');

if (tooltip) {
	const toggleTooltip = (element, toggle) => {
		const parentElem = element.closest('.tooltip');
		parentElem.classList.toggle('is-open', toggle);
	};

	const closeTooltip = () => {
		const openTooltips = document.querySelectorAll('.tooltip.is-open');
		openTooltips.forEach((item) => {
			item.classList.remove('is-open');
		});
	};

	attachEvent('[data-tooltip-button]', 'click', (ev) => {
		toggleTooltip(ev.target, true);
		ev.preventDefault();
	});

	attachEvent('[data-close-tooltip]', 'click', (ev) => {
		toggleTooltip(ev.target, false);
		ev.preventDefault();
	});

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			closeTooltip();
		}
	});
}
