import { attachEvent, toggleBodyScrollLock } from '../../../../js/default';

const alertPopup = document.querySelector('.alert-popup');

if (alertPopup) {
	// Open/Close Alert Modal
	const toggleAlertPopup = (ele, toggle) => {
		ele?.classList.toggle('is-open-modal', toggle);
		toggleBodyScrollLock(toggle);
	};

	const closeAlertPopups = () => {
		document.querySelectorAll('.alert-popup').forEach((el) => toggleAlertPopup(el, false));
	};

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			closeAlertPopups();
		}
	});

	attachEvent('[data-close-popup]', 'click', (ev) => {
		toggleAlertPopup(ev.target.closest('.alert-popup'), false);
		ev.preventDefault();
	});
}
