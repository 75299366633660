import Cookies from 'js-cookie';
import { attachEvent, disableButton, parseCurrencyToNumber, toggleCartClass, toggleSearchClass } from '../../../../js/default';
import { request } from '../../../../js/api';
import { addToCart } from '../../../../js/fb-pixel';

const cartElem = document.querySelector('.cart');

if (cartElem) {
	const getCurrentVariationId = (configurationElement, withVariant = false) => {
		if (!configurationElement || !configurationElement.dataset.variations) {
			return 0;
		}
		if (configurationElement.dataset.id) {
			return configurationElement.dataset.id;
		}
		const variationsData = JSON.parse(configurationElement.dataset.variations);
		const selectedColorElement = configurationElement.querySelector('.color-selection__color.is-selected');
		const color = selectedColorElement ? selectedColorElement.getAttribute('aria-label') : null;

		const selectedSizeElement = configurationElement.querySelector('.dropdown__list-item input:checked');
		const size = selectedSizeElement ? selectedSizeElement.value : null;
		for (const variation of variationsData) {
			if (color === variation.data[0] && (size === variation.data[1] || size === null)) {
				return !withVariant
					? variation.id
					: {
							productId: variation.id,
							color,
							name: variation.name,
						};
			}
		}
		return 0;
	};

	const toggleOpenSideCart = () => {
		const isOpen = document.body.classList.contains('is-open-cart');
		const isSearchOpen = document.body.classList.contains('is-open-search');
		toggleCartClass(!isOpen);
		if (isSearchOpen) {
			toggleSearchClass(false);
		}
	};

	const removeInfoTextOnCart = () => {
		document.querySelectorAll('.cart__popup-info').forEach((item) => {
			item.remove();
		});
	};
	const populateDynamicDataCart = (result) => {
		const selectorsToUpdate = [
			['.cart__popup-footer-right', 'price'],
			['.cart__popup-header .title--h3', 'title'],
			['.cart-content__title h1', 'title'],
			['i.cart__btn-number', 'count'],
			['.cart__btn-text', 'price'],
			['.cart-summary__footer-top .text-right', 'price'],
			['.cart-without-vat .cart-summary__price-right', 'price_without_tax'],
			['.cart-vat .cart-summary__price-right', 'price_tax'],
			['.class-saved-amount .cart-summary__price-right', 'saved'],
			['.class-shipping-amount .cart-summary__price-right', 'shipping'],
		];
		selectorsToUpdate.forEach(([selector, property]) => {
			if (selector !== '.cart-vat .cart-summary__price-right') {
				const element = document.querySelector(selector);
				if (element) {
					element.innerHTML = result[property];
					if (property === 'count' && !result[property]) {
						element.remove();
					}
				}
			} else {
				const elements = document.querySelectorAll(selector);
				if (elements.length) {
					elements.forEach((element, index) => {
						if (result[property][index]?.text) {
							element.innerHTML = result[property][index]?.text || '';
							return;
						}

						element.closest('.cart-summary__price-item')?.remove();
					});
				}
			}
		});
	};

	const setCartData = async (panel, body, addRemoveTruFalse) => {
		window.dataLayer = window.dataLayer || [];

		const rawData = document.getElementById(`product-ld-data-${!body.variationName ? body.product_id : body.parent_id}`);
		const parsedData = JSON.parse(rawData.textContent);

		window.dataLayer.push({
			event: body.add ? 'add_to_cart' : 'remove_from_cart',
			ecommerce: {
				currency: 'EUR',
				value: parseCurrencyToNumber('sl-SI', 'EUR', parsedData.value),
				items: [
					{
						item_id: body.product_id,
						item_name: body?.variationName || parsedData?.item_name,
						discount: parsedData?.discount,
						item_brand: parsedData?.item_brand,
						item_category: parsedData?.item_category,
						item_category2: parsedData?.item_category2,
						item_variant: body.variationColor || null,
						price: parseCurrencyToNumber('sl-SI', 'EUR', parsedData.price),
						quantity: parsedData?.quantity,
					},
				],
			},
		});

		if (addRemoveTruFalse.removeInfoText) {
			removeInfoTextOnCart();
		}

		if (addRemoveTruFalse.fillCart) {
			body.event_id = addToCart(body.product_id, body.quantity);
		}

		body.hash = Cookies.get('cart_hash') || '';
		const result = await request(`${window.location.origin}/api/v1/cart`, {
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			body: JSON.stringify(body),
		});
		populateDynamicDataCart(result);

		if (result.count === '') {
			const submitBtn = document.querySelector('.checkout_btn');
			const errorText = document.querySelector('.cart-summary__cart-empty');
			errorText?.classList.toggle('hide', false);
			submitBtn?.classList.toggle('btn--disabled', true);
		}

		if (!result.no_stock) {
			const submitBtn = document.querySelector('.checkout_btn');
			const errorText = document.querySelector('.cart-summary__no-stock');
			submitBtn?.classList.toggle('btn--disabled', result.count === '');
			errorText?.classList.toggle('hide', true);
		}

		if (addRemoveTruFalse.fillCart && document.querySelector('.cart')) {
			document.querySelector('.cart').outerHTML = result.html;
		}
		if (addRemoveTruFalse.fillCartPage && document.querySelector('.sc-cart-content')) {
			document.querySelector('.sc-cart-content').outerHTML = result.html_cart_page;
		}
		if (result.cart_hash && !Cookies.get('cart_hash')) {
			Cookies.set('cart_hash', result.cart_hash, { secure: false, expires: 365 });
		}
		if (addRemoveTruFalse.addRemoveClass) {
			panel.classList.add('is-removed');
			panel.innerHTML = result.removed_html;
		}
		if (result.html_gift_modal) {
			document.querySelector('.sc-compare-product-popup .gift-popup').innerHTML = result.html_gift_modal;
		}
		if (addRemoveTruFalse.openSide) {
			setTimeout(() => {
				toggleOpenSideCart();
			}, 50);
		}
	};

	attachEvent('.cart__popup-empty a', 'click', () => {
		toggleOpenSideCart();
	});

	attachEvent('[data-cart]', 'click', () => {
		toggleOpenSideCart();
	});

	attachEvent('[data-close-cart]', 'click', (ev) => {
		toggleCartClass(false);
		ev.preventDefault();
	});

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			toggleCartClass(false);
		}
	});

	// Close cart on outside click
	document.addEventListener('click', (evt) => {
		let targetElement = evt.target; // clicked element
		do {
			if (targetElement === cartElem) {
				return;
			}
			targetElement = targetElement.parentNode;
		} while (targetElement);
	});

	// Add to cart - listing & detail view
	attachEvent(
		'.configuration-popup-trigger, .product-summary__add-to-cart-btn, .comparison-table__product-list-slide .btn--yellow',
		'click',
		async (ev) => {
			ev.preventDefault();
			if (
				!ev.target.closest('.is-configurable') &&
				!ev.target.closest('.product-summary__inner')?.querySelector('.product-summary__configuration')
			) {
				await disableButton(ev.target, async () => {
					const productFooter = ev.target.closest('.product-card__box, .comparison-table__product-list-slide');
					await setCartData(
						productFooter,
						{
							product_id: Number(productFooter.dataset.id),
							quantity: 1,
							add: true,
						},
						{
							removeInfoText: true,
							fillCart: true,
							fillCartPage: true,
							openSide: true,
							addRemoveClass: false,
						},
					);
				});
			}
		},
	);

	attachEvent('.configuration-popup__configuration .color-selection__color[data-id]', 'click', (ev) => {
		ev.target.closest('.configuration-popup__configuration').dataset.id = ev.target.closest('button').dataset.id;
	});

	// Add to cart Variation - listing & detail view
	attachEvent('.configuration-popup__footer .btn--default, .product-summary__add-to-cart-btn', 'click', async (ev) => {
		ev.preventDefault();

		await disableButton(ev.target, async () => {
			const productFooter = ev.target.closest('.product-card__box');
			const selectedSizeElementAll = ev.target
				.closest('.configuration-popup__inner, .product-card__box')
				.querySelectorAll('.dropdown__list-item input');
			const checked = Array.from(selectedSizeElementAll).some((item) => item.checked);
			const configurationPopup = ev.target.closest('.configuration-popup__inner, .product-card__box');
			if (configurationPopup && configurationPopup.querySelector('.dropdown')) {
				configurationPopup.querySelector('.dropdown').classList.toggle('is-validation-failed', !checked);
			}
			if (checked || (!checked && configurationPopup && !configurationPopup.querySelector('.dropdown'))) {
				const { productId, color, name } = getCurrentVariationId(
					ev.target
						.closest('.configuration-popup, .product-card__box')
						.querySelector('.configuration-popup__configuration, .product-summary__configuration'),
					true,
				);

				const parentId = ev.target
					.closest('.configuration-popup, .product-card__box')
					.querySelector('.configuration-popup__configuration')?.id;

				if (productId) {
					await setCartData(
						productFooter,
						{
							parent_id: Number(parentId),
							product_id: Number(productId),
							variationColor: color || null,
							variationName: name || null,
							quantity: 1,
							add: true,
						},
						{
							removeInfoText: true,
							fillCart: true,
							fillCartPage: false,
							openSide: true,
							addRemoveClass: false,
						},
					);
					ev.target.closest('.configuration-popup')?.classList.remove('is-open-modal');
					document.body.classList.remove('body-scroll-locked');
				}
			}
		});
	});
	// Undo & add back to cart - side cart
	attachEvent('.cart__popup-inner button.cart-product-list__undo', 'click', async (ev) => {
		ev.preventDefault();
		await disableButton(ev.target, async () => {
			const productFooter = ev.target.closest('.product-preview');
			await setCartData(
				productFooter,
				{
					product_id: Number(productFooter.dataset.id),
					quantity: 1,
					add: true,
				},
				{
					removeInfoText: true,
					fillCart: true,
					fillCartPage: true,
					openSide: false,
					addRemoveClass: false,
				},
			);
		});
	});
	// Remove item - side cart
	attachEvent('.product-preview .product-preview__delete', 'click', async (ev) => {
		ev.preventDefault();
		await disableButton(ev.target, async () => {
			const productFooter = ev.target.closest('.product-preview');
			await setCartData(
				productFooter,
				{
					product_id: Number(productFooter.dataset.id),
					quantity: 0,
				},
				{
					removeInfoText: true,
					fillCart: false,
					fillCartPage: true,
					openSide: false,
					addRemoveClass: true,
				},
			);
		});
	});
	// Remove item - cart page
	attachEvent('.cart-product__remove-btn', 'click', async (ev) => {
		ev.preventDefault();
		await disableButton(ev.target, async () => {
			const productFooter = ev.target.closest('.cart-product');
			await setCartData(
				productFooter,
				{
					product_id: Number(productFooter.dataset.id),
					quantity: -1,
				},
				{
					removeInfoText: true,
					fillCart: true,
					fillCartPage: false,
					openSide: false,
					addRemoveClass: true,
				},
			);
		});
	});
	// Undo & add back to cart - cart page
	attachEvent('.sc-cart-content .cart-product-list__undo', 'click', async (ev) => {
		ev.preventDefault();
		await disableButton(ev.target, async () => {
			const productFooter = ev.target.closest('.cart-product');
			await setCartData(
				productFooter,
				{
					product_id: Number(productFooter.dataset.id),
					quantity: 1,
				},
				{
					removeInfoText: true,
					fillCart: true,
					fillCartPage: true,
					openSide: false,
					addRemoveClass: false,
				},
			);
		});
	});
	// Add & remove inputs number - cart page
	attachEvent('.quantity-counter__button', 'click', (ev) => {
		ev.preventDefault();
		const productFooter = ev.target.closest('.cart-product');
		setTimeout(async () => {
			const inputNumber = Number(productFooter.querySelector('input').value);
			await setCartData(
				productFooter,
				{
					product_id: Number(productFooter.dataset.id),
					quantity: inputNumber,
				},
				{
					removeInfoText: true,
					fillCart: true,
					fillCartPage: false,
					openSide: false,
					addRemoveClass: inputNumber === 0,
				},
			);
		}, 50);
	});
	// Add gift modal
	attachEvent('.gift-popup-box__footer span', 'click', (ev) => {
		ev.preventDefault();
		const giftElements = document.querySelectorAll('.gift-popup-box__gift');
		giftElements.forEach((element) => {
			const actionId = Number(element.dataset.actionId || 0);
			const productId = Number(element.dataset.id || 0);
			if (actionId && productId) {
				setCartData(
					ev.target,
					{
						product_id: productId,
						quantity: 1,
						add: true,
						action_id: actionId,
					},
					{
						removeInfoText: true,
						fillCart: true,
						fillCartPage: false,
						openSide: false,
						addRemoveClass: false,
					},
				);
			}
		});
		ev.target.closest('.gift-popup-box')?.classList.remove('is-open-modal');
		document.body.classList.remove('body-scroll-locked');
	});
	attachEvent('.gift-popup .gift-selection', 'click', (ev) => {
		ev.preventDefault();
		const giftBox = ev.target.closest('.gift-popup-box__gift');
		const giftSelection = ev.target.closest('.gift-selection');
		const footerSpan = ev.target.closest('.gift-popup-box').querySelector('.gift-popup-box__footer span');
		const link = ev.target.closest('a');
		if (link?.dataset.id) {
			giftBox.querySelectorAll('.is-selected').forEach((item) => item.classList.remove('is-selected'));
			giftBox.dataset.id = link.dataset.id;
			giftSelection.classList.add('is-selected');
			footerSpan.classList.remove('btn--disabled');
		}
	});

	const submitBtn = document.querySelector('.cart-summary__buttons > .checkout_btn');
	if (submitBtn) {
		submitBtn.addEventListener('click', (ev) => {
			ev.preventDefault();
			submitBtn.style.opacity = 0.5;
			submitBtn.style.pointerEvents = 'none';

			window.dataLayer = window.dataLayer || [];

			const valueText = document.querySelector('.cart-summary__footer-col.text-right');
			const valueNumber = parseCurrencyToNumber('sl-SI', 'EUR', valueText.textContent);
			const products = document.querySelectorAll('.cart-product');
			let itemsArray = [];
			products?.forEach((product) => {
				const exists = itemsArray.find((item) => product.dataset.id === item.item_id);

				if (!exists) {
					const rawData = document.getElementById(`product-ld-data-${product.dataset.id}`);
					const parsedData = JSON.parse(rawData.textContent);

					let count = 0;
					products.forEach((item) => {
						if (item.dataset.id === parsedData.item_id) {
							count += 1;
						}
					});

					itemsArray = [
						...itemsArray,
						{
							item_id: parsedData.item_id,
							item_name: parsedData.item_name,
							discount: parsedData.discount,
							item_brand: parsedData.item_brand,
							item_category: parsedData.item_category,
							item_category2: parsedData.item_category2,
							item_variant: parsedData?.variation,
							price: parsedData.price,
							quantity: count,
						},
					];
				}
			});

			dataLayer.push({
				event: 'begin_checkout',
				ecommerce: {
					currency: 'EUR',
					value: valueNumber,
					items: itemsArray,
				},
			});

			window.location.href = ev.target.href;
		});
	}
}
