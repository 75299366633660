import handleForm from '../../../../js/form-validation.js';

window.addEventListener('DOMContentLoaded', () => {
	const useEmail = document.querySelector('.sign-in-module .guest');

	if (useEmail) {
		const fv = handleForm(useEmail);

		const submitBtn = useEmail.querySelector('.btn');
		const inputElement = useEmail.querySelector('input[name="sign_in_email"]');

		fv.on('core.form.valid', () => {
			window.location.href = `/checkout?email=${inputElement?.value}`;
		});

		submitBtn.addEventListener('click', (ev) => {
			ev.preventDefault();
			fv.validate();
		});
	}
});
