import { attachEvent, toggleBodyScrollLock } from '../../../../js/default';
import { request } from '../../../../js/api.js';
import handleForm from '../../../../js/form-validation.js';

const ratingModal = document.querySelector('.rating-modal');

if (ratingModal) {
	const fv = handleForm(ratingModal);

	const closeModals = () => {
		const openModals = document.querySelectorAll('.rating-modal.is-open-modal');
		openModals.forEach((modal) => {
			modal.classList.remove('is-open-modal');
		});
		toggleBodyScrollLock(false);
	};

	const resetModalValues = () => {
		const stars = document.querySelectorAll('.rating-modal__stars-input');
		stars.forEach((star) => {
			star.checked = false;
		});

		const rateTitle = document.getElementById('rate_title');
		const rateComment = document.getElementById('rate_comment');
		rateTitle.value = '';
		rateComment.value = '';
		rateTitle.closest('.mod-input').classList.toggle('is-focused', false);
		rateComment.closest('.mod-input').classList.toggle('is-focused', false);
	};

	// Open Modal
	attachEvent('[data-modal-trigger]', 'click', (ev) => {
		const parentElement = ev.target.closest('[data-modal-parent]');
		const targetModalValue = ev.target.getAttribute('data-modal-trigger');
		const targetModal = parentElement.querySelector(`[data-modal="${targetModalValue}"]`);

		const wrapper = ev.target.closest('.product-preview');
		const imageElement = wrapper.querySelector('.product-preview__image > div > img')?.src;
		const titleElement = wrapper.querySelector('.product-preview__link > strong')?.textContent;

		targetModal.querySelector('.rating-modal__product-image > img').src = imageElement;
		targetModal.querySelector('.rating-modal__product-title > div > h2').textContent = titleElement;

		resetModalValues();

		const { order, id } = wrapper.dataset;

		const submitBtn = targetModal.querySelector('.rating-modal__form-submit-btn');
		submitBtn.dataset.productId = id;
		submitBtn.dataset.orderId = order;

		if (targetModal) {
			targetModal.classList.add('is-open-modal');
			toggleBodyScrollLock(true);
		}
		ev.preventDefault();
	});

	// Close Modal
	attachEvent('[data-close-modal]', 'click', (ev) => {
		closeModals(ev.target);
		ev.preventDefault();
	});

	// Close Modal on ESC key press
	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			closeModals();
		}
	});

	fv.addField('rating-group', {
		validators: {
			validateRating: {},
		},
	});

	document.querySelector('.rating-modal__form-submit-btn').addEventListener('click', async () => {
		fv.validate();
	});

	fv.on('core.form.valid', async () => {
		const stars = document.querySelectorAll('.rating-modal__stars-input:checked');
		const rateValue = Math.max(...Array.from(stars).map((star) => Number(star.value)));

		const rateTitle = document.getElementById('rate_title');
		const rateComment = document.getElementById('rate_comment');

		const { dataset } = document.querySelector('.rating-modal__form-submit-btn');
		const { productId, orderId } = dataset;

		const response = await request(
			`${window.location.origin}/api/v1/review/`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					order_id: Number(orderId),
					product_id: Number(productId),
					title: rateTitle.value,
					content: rateComment.value,
					rating: rateValue,
				}),
			},
			true,
		);

		const alertPopup = document.querySelector('.alert-popup');
		if (response.status) {
			alertPopup.querySelector('.title').textContent = 'Oops!';
			alertPopup.querySelector('.alert-popup__content').textContent = response?.message;
		}

		if (!response.status) {
			const parent = document.querySelector(`.product-preview[data-id="${productId}"][data-order="${orderId}"]`);

			parent.querySelector('.product-preview__right-link[data-modal-trigger="rating-modal"]').style.display = 'none';
			parent.querySelector('.product-preview__success').classList.toggle('hide', false);
		}

		alertPopup.classList.toggle('is-open-modal', true);

		closeModals();
	});
}

const ratingStars = document.querySelectorAll('.rating-modal__stars-input');

ratingStars.forEach((el) => {
	el.addEventListener('change', (ev) => {
		const parentUl = ev.target.closest('ul');
		const parentAllLi = parentUl.querySelectorAll('li');
		const clickedElemIndex = ev.target.getAttribute('data-rate');

		parentAllLi.forEach((elm, i) => {
			const starInput = elm.querySelector('.rating-modal__stars-input');
			starInput.checked = i <= clickedElemIndex;
		});
	});
});
