// Add focus class while start typing
const inputs = document.querySelectorAll('.mod-input__field');

inputs.forEach((input) => {
	const parent = input.closest('.mod-input');
	input.addEventListener('focus', () => {
		parent.classList.add('is-focused');
	});

	input.addEventListener('blur', () => {
		if (!input.value) {
			parent.classList.remove('is-focused');
		}
	});
});

// Textarea character counter
const textArea = document.querySelectorAll('.textarea-with-counter');

textArea.forEach((textarea) => {
	const parentElement = textarea.closest('.mod-input');
	textarea.addEventListener('input', (input) => {
		const characterCountElement = parentElement.querySelector('.mod-input__curr-character');
		characterCountElement.textContent = `${input.target.value.length}`;
	});
});
