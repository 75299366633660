// **********************************************************
// Brands Slider
// **********************************************************

import Swiper from 'swiper/bundle';
import { windowWidth, enableScrollbarSync } from '../../../../js/default';

const brandsSlider = document.querySelectorAll('[data-slider="brands-slider"]');

const brandsSliderOptions = {
	slidesPerView: 3.4,
	spaceBetween: 20,
	watchOverflow: true,
	speed: 800,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
	breakpoints: {
		767: {
			freeMode: true,
			slidesPerView: 4.5,
			spaceBetween: 30,
		},
		1023: {
			freeMode: false,
			slidesPerView: 5,
			spaceBetween: 30,
		},
		1199: {
			freeMode: false,
			slidesPerView: 8,
			spaceBetween: 40,
		},
	},
};

const initBrandsSlider = () => {
	brandsSlider.forEach((el) => {
		const brandsSliderEle = el.closest('.brands-slider');
		const arrowRight = brandsSliderEle.querySelector('.swiper-button-next');
		const arrowLeft = brandsSliderEle.querySelector('.swiper-button-prev');
		const scrollbarEle = brandsSliderEle.querySelector('.swiper-scrollbar');

		brandsSliderOptions.navigation.nextEl = arrowRight;
		brandsSliderOptions.navigation.prevEl = arrowLeft;
		brandsSliderOptions.scrollbar.el = scrollbarEle;

		if (windowWidth() > 767) {
			new Swiper(el, brandsSliderOptions);
		} else {
			enableScrollbarSync(brandsSliderEle);
		}
	});
};

initBrandsSlider();

window.addEventListener('resize', () => {
	brandsSlider.forEach((el) => {
		const brandsSliderEle = el.closest('.brands-slider');

		if (windowWidth() > 767) {
			if (!el.swiper) {
				const swiperEle = new Swiper(el, brandsSliderOptions);
				swiperEle.update();
			}
		} else {
			enableScrollbarSync(brandsSliderEle);

			if (el.swiper) {
				el.swiper.destroy();
			}
		}
	});
});
