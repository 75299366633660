import { attachEvent, toggleCategoriesClass, removeActiveClass, toggleActiveClass } from '../../../../js/default';

const allDropdowns = document.querySelectorAll('.menu__products');

if (allDropdowns) {
	attachEvent('[data-categories-btn]', 'click', (ev) => {
		const isOpen = document.body.classList.contains('is-open-categories');
		const openList = document.querySelector('.menu__list.is-open-submenu');
		toggleCategoriesClass(!isOpen);
		removeActiveClass(allDropdowns);
		openList?.classList.remove('is-open-submenu');
		ev.preventDefault();
	});

	// Open/Close subitems on hover
	attachEvent('.has-sub > .menu__link', 'click', (ev) => {
		const parent = ev.target.closest('.menu__item');
		const parentList = ev.target.closest('.menu__list');
		const targetId = parent.getAttribute('data-item');
		const targetDropdown = document.querySelector(`[data-dropdown="${targetId}"]`);
		parentList.classList.add('is-open-submenu');
		removeActiveClass(allDropdowns);
		toggleActiveClass(targetDropdown, true);
		ev.preventDefault();
	});

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			toggleCategoriesClass(false);
			removeActiveClass(allDropdowns);
		}
	});

	// Close menu on outside click
	const menu = document.querySelector('.menu');
	document.addEventListener('click', (evt) => {
		let targetElement = evt.target; // clicked element

		do {
			if (targetElement === menu) {
				return;
			}
			targetElement = targetElement.parentNode;
		} while (targetElement);

		toggleCategoriesClass(false);
	});
}
