import handleForm from '../../../../js/form-validation';
import { request } from '../../../../js/api';

// eslint-disable-next-line import/prefer-default-export
export const initializeNewsletterForm = () => {
	const container = document.querySelector('.newsletter__inner');
	const form = document.querySelector('.newsletter__form');

	if (form) {
		const submitBtn = form.querySelector('button.btn');
		const inputElems = form.querySelectorAll('input');
		const newsletterError = document.querySelector('.newsletter__form-error');
		const fv = handleForm(form);

		fv.on('core.form.valid', async () => {
			const formValues = Array.from(inputElems).reduce((accumulator, element) => {
				accumulator[element.name] = element.value;
				return accumulator;
			}, {});

			try {
				await request(`${window.location.origin}/api/v1/newsletter/store`, {
					method: 'POST',
					headers: {
						'content-type': 'application/json',
					},
					body: JSON.stringify(formValues),
				});

				container.querySelector('.newsletter__body').style.display = 'none';
				container.querySelector('.newsletter__thankyou').style.display = 'block';
			} catch (error) {
				newsletterError.classList.toggle('hide', false);
				const errorText = newsletterError.querySelector('.newsletter__form-error-text');
				errorText.textContent = `${error}`.replace('Error: ', '');
			}
		});

		submitBtn.addEventListener('click', (ev) => {
			ev.preventDefault();
			newsletterError.classList.toggle('hide', true);
			fv.validate();
		});
	}
};

initializeNewsletterForm();
