// **********************************************************
// Comparison Table
// **********************************************************

// eslint-disable-next-line import/no-unresolved
import Swiper from 'swiper/bundle';
import Cookies from 'js-cookie';
import { attachEvent } from '../../../../js/default';

const comparisonProductSlider = document.querySelectorAll('[data-slider="comparison-product"]');
const comparisonProductThumbSlider = document.querySelectorAll('[data-slider="comparison-product-thumb"]');

// Thumb Slider
const comparisonProductThumbSliderOptions = {
	slidesPerView: 1,
	spaceBetween: 10,
	speed: 800,
	watchOverflow: true,
	breakpoints: {
		767: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
	},
};

comparisonProductThumbSlider.forEach((el) => new Swiper(el, comparisonProductThumbSliderOptions));

// Main Slider
const comparisonProductSliderOptions = {
	slidesPerView: 1,
	spaceBetween: 10,
	speed: 800,
	watchOverflow: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
	thumbs: {
		swiper: false,
	},
	breakpoints: {
		767: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1023: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
	},
};

comparisonProductSlider.forEach((el) => {
	const comparisonProductSliderEle = el.closest('.comparison-table');
	const arrowRight = comparisonProductSliderEle.querySelector('.comparison-table__slider-arrow .swiper-button-next');
	const arrowLeft = comparisonProductSliderEle.querySelector('.comparison-table__slider-arrow .swiper-button-prev');
	const scrollbarEle = comparisonProductSliderEle.querySelector('.comparison-table__scrollbar');

	comparisonProductSliderOptions.navigation.nextEl = arrowRight;
	comparisonProductSliderOptions.navigation.prevEl = arrowLeft;
	comparisonProductSliderOptions.scrollbar.el = scrollbarEle;

	const thumbSliderElement = comparisonProductSliderEle.querySelector('[data-slider="comparison-product-thumb"]');
	const thumbSlider = thumbSliderElement.swiper;
	const mainSlider = new Swiper(el, comparisonProductSliderOptions);

	thumbSlider.controller.control = mainSlider;
	mainSlider.controller.control = thumbSlider;
});

// ---
// Manage Product Data Height
// ---

const productDataEleHeightCalc = () => {
	const comparisonTableModule = document.querySelector('.comparison-table');

	if (comparisonTableModule) {
		const productDataEle = comparisonTableModule.querySelectorAll('[comparison-data]');
		const maxHeightMap = {};

		productDataEle.forEach((ele) => {
			const key = ele.getAttribute('comparison-data');
			const { height } = ele.getBoundingClientRect();
			const value = Math.max(maxHeightMap[key] || 0, height);

			comparisonTableModule.querySelectorAll(`[comparison-data="${key}"]`).forEach((ele) => {
				ele.style.minHeight = `${value}px`;
			});
		});
	}
};

window.addEventListener('DOMContentLoaded', () => {
	setTimeout(() => {
		productDataEleHeightCalc();
	}, 300);
});

window.addEventListener('resize', () => {
	setTimeout(() => {
		productDataEleHeightCalc();
	}, 300);
});

// ---
// Remove Product
// ---

const removeAddProduct = (ele, slideEle, sliderEle, parentSection) => {
	const slide = ele.closest(slideEle);
	const slider = parentSection.querySelector(sliderEle);
	const clonedSlide = slide.cloneNode(true);

	clonedSlide.classList.add('is-added');
	const allSlides = parentSection.querySelectorAll(slideEle);
	const allAddedSlides = parentSection.querySelectorAll(`${slideEle}.is-added`);
	slider.swiper.appendSlide([clonedSlide]);

	if (allSlides.length > 3 && allAddedSlides.length > 0) {
		clonedSlide.remove();
	}
};

attachEvent('.comparison-table__product-remove', 'click', (ev) => {
	const currentData = JSON.parse(decodeURIComponent(Cookies.get('compare_items') || '[]'));
	const index = currentData.indexOf(
		Number(ev.target.closest('.comparison-table__product-list-slide').dataset.id)
	);
	if (index !== -1) {
		currentData.splice(index, 1);
	}
	Cookies.set('compare_items', JSON.stringify(currentData), {
		expires: 31,
		secure: true
	});
	const parentSection = ev.target.closest('section');
	const parentSlide = ev.target.closest('.comparison-table__product-list-slide');
	const targetSlideValue = parentSlide.getAttribute('data-slide');

	const targetSameSlide = parentSection.querySelectorAll(`[data-slide="${targetSlideValue}"]`);

	targetSameSlide.forEach((ele) => {
		ele.querySelector('.comparison-table__product')?.remove();
		ele.querySelector('.comparison-table__thumb-slide')?.remove();

		if (ele.closest('.comparison-table__product-list-slide')) {
			removeAddProduct(ele, '.comparison-table__product-list-slide', '[data-slider="comparison-product"]', parentSection);
		}

		if (ele.closest('.comparison-table__thumb-slider-slide')) {
			removeAddProduct(ele, '.comparison-table__thumb-slider-slide', '[data-slider="comparison-product-thumb"]', parentSection);
		}

		parentSection.querySelectorAll('.swiper').forEach((swiper) => {
			setTimeout(() => {
				swiper.swiper.update();
			}, 100);
		});

		ele.remove();
		if (currentData.length === 0) {
			window.location.reload();
		}
	});
});
