// *******************************************************************************
// Delivery Methods
// *******************************************************************************

import { attachEvent } from '../../../../js/default';

attachEvent('.delivery-methods__heading input', 'change', (ev) => {
	const parentEle = ev.target.closest('.delivery-methods__list-item');
	const subItems = parentEle.querySelector('.delivery-methods__sub-list');

	subItems?.classList.toggle('hide');
});
