// **********************************************************
// Tag Collections
// **********************************************************
import Swiper from 'swiper/bundle';
import { attachEvent, toggleConfigurationPopup, windowWidth, enableScrollbarSync } from '../../../../js/default';

const tagCollectionsSlider = document.querySelectorAll('[data-slider="tag-collections-slider"]');
const tagCollectionsSliderOptions = {
	slidesPerView: 1.34,
	spaceBetween: 20,
	watchOverflow: true,
	speed: 1000,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
	breakpoints: {
		767: {
			freeMode: true,
			slidesPerView: 2,
			spaceBetween: 26,
		},
		1023: {
			freeMode: false,
			slidesPerView: 3,
			spaceBetween: 26,
		},
		1199: {
			freeMode: false,
			slidesPerView: 4,
			spaceBetween: 26,
		},
	},
};

// eslint-disable-next-line import/prefer-default-export
export const initTagCollectionsSlider = () => {
	const tagCollectionsSlider = document.querySelectorAll('[data-slider="tag-collections-slider"]');
	tagCollectionsSlider.forEach((el) => {
		try {
			const tagCollectionsSliderEle = el.closest('.tag-collections');
			const arrowRight = tagCollectionsSliderEle.querySelector('.tag-collections__header .swiper-button-next');
			const arrowLeft = tagCollectionsSliderEle.querySelector('.tag-collections__header .swiper-button-prev');
			const scrollbarEle = tagCollectionsSliderEle.querySelector('.tag-collections__swiper-scrollbar');

			tagCollectionsSliderOptions.navigation.nextEl = arrowRight;
			tagCollectionsSliderOptions.navigation.prevEl = arrowLeft;
			tagCollectionsSliderOptions.scrollbar.el = scrollbarEle;

			if (tagCollectionsSliderEle.classList.contains('tag-collections--with-collection-banner')) {
				tagCollectionsSliderOptions.breakpoints = {
					767: {
						slidesPerView: 2,
						spaceBetween: 26,
					},
					1199: {
						freeMode: false,
						slidesPerView: 3,
						spaceBetween: 26,
					},
				};
			}

			if (windowWidth() > 767) {
				new Swiper(el, tagCollectionsSliderOptions);
			} else {
				enableScrollbarSync(tagCollectionsSliderEle, scrollbarEle);
			}
		} catch (e) {
			console.error(e);
		}
	});
};

initTagCollectionsSlider();

window.addEventListener('resize', () => {
	tagCollectionsSlider.forEach((el) => {
		const tagCollectionsSliderEle = el.closest('.tag-collections');
		const scrollbarEle = tagCollectionsSliderEle.querySelector('.tag-collections__swiper-scrollbar');

		if (windowWidth() > 767) {
			if (!el.swiper) {
				const options = { ...tagCollectionsSliderOptions };
				options.scrollbar.el = scrollbarEle;
				const swiperEle = new Swiper(el, options);
				swiperEle.update();
			}
		} else {
			enableScrollbarSync(tagCollectionsSliderEle, scrollbarEle);

			if (el.swiper) {
				el.swiper.destroy();
			}
		}
	});
});

// *********************
// Open/Close Modal
// *********************

attachEvent('.is-configurable .configuration-popup-trigger', 'click', (ev) => {
	const parentEle = ev.target.closest('.is-configurable');
	const targetModal = parentEle?.getAttribute('data-target-modal');
	const parentSection = parentEle.closest('section');
	const modalElement = parentSection?.querySelector(`[data-modal="${targetModal}"]`);
	if (modalElement) {
		ev.preventDefault();
		toggleConfigurationPopup(modalElement, true);
	}
});
