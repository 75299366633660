// **********************************************************
// Product Image Slider
// **********************************************************

import Swiper from 'swiper/bundle';

const productImageSlider = document.querySelectorAll('[data-slider="product-image-slider"]');

const productImageSliderOptions = {
	slidesPerView: 1,
	spaceBetween: 15,
	speed: 600,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	effect: 'creative',
	creativeEffect: {
		prev: {
			translate: ['-120%', 0, -500],
		},
		next: {
			translate: ['120%', 0, -500],
		},
	},
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
};

productImageSlider.forEach((el) => {
	const productImageSliderEle = el.closest('.product-image-slider');
	const arrowRight = productImageSliderEle.querySelector('.swiper-button-next');
	const arrowLeft = productImageSliderEle.querySelector('.swiper-button-prev');
	const scrollbarEle = productImageSliderEle.querySelector('.swiper-scrollbar');

	productImageSliderOptions.navigation.nextEl = arrowRight;
	productImageSliderOptions.navigation.prevEl = arrowLeft;
	productImageSliderOptions.scrollbar.el = scrollbarEle;

	new Swiper(el, productImageSliderOptions);
});
